import { PropsWithChildren } from 'react';
import tw, { styled } from 'twin.macro';

interface ITypographyProps {}

const TypographyContainer = styled.div`
a {
  ${tw`text-blue-500 underline`}

  &:hover {
    ${tw`text-blue-400`}
  }
}
`;

export const Typography = (props: PropsWithChildren<ITypographyProps>) => {
  const { children } = props;

  return <TypographyContainer>{children}</TypographyContainer>;
};
