import { Link } from "gatsby";
import tw, { styled } from "twin.macro";

interface IFooterProps {}

const FooterWrapper = styled.footer`
.legal {
  .legal-link {
    text-decoration: underline;

    &:hover {
      ${tw`text-gray-600`}
    }
  }
}
`;

export const Footer = (props: IFooterProps) => {
  return (
    <FooterWrapper>
      <div className="container mx-auto p-4 flex sm:flex-col md:flex-row md:justify-between">
        Copyright &copy; { new Date().getFullYear() } Plamo Club.
        <div className="legal">
          <ul>
            <li><Link className="legal-link" to="/page/privacy-policy">Privacy</Link></li>
          </ul>
        </div>
      </div>
    </FooterWrapper>
  );
}
