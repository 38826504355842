import React, { PropsWithChildren } from 'react';
import { Notice } from '../Notice';
import { Footer } from './Footer';
import { Header } from './Header';

interface ILayoutProps {}

export const Layout = (props: PropsWithChildren<ILayoutProps>) => {
  const { children } = props;
  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <main className="flex-grow">
        <div className="container mx-auto p-4">
          <Notice />
          {children}
        </div>
      </main>
      <Footer />
    </div>
  );
};
