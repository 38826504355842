import { graphql, useStaticQuery } from 'gatsby';
import parse from 'html-react-parser';
import { Typography } from '../Typography';

interface INoticeProps {}

export const Notice = (props: INoticeProps) => {
  const data = useStaticQuery(graphql`
    query NoticeQuery {
      wpPage(title: { eq: "Notice" }) {
        content
      }
    }
  `);

  const content = data.wpPage.content;

  if (!content) {
    return null;
  }

  return (
    <div className="mx-auto p-4 bg-yellow-200 border-2 border-yellow-400 rounded-md mb-2">
      <Typography>{parse(content)}</Typography>
    </div>
  );
};
