import { Link } from "gatsby";
import tw, { styled } from "twin.macro";

interface IHeaderProps {}

const HeaderWrapper = styled.header`
  .header-nav {
    ul {
      li {
        a {
          text-decoration: underline;

          &:hover {
            ${tw`text-gray-600`}
          }
        }
      }
    }
  }
`;

export const Header = (props: IHeaderProps) => {
  return (
    <HeaderWrapper className="bg-white border-b">
      <div className="container mx-auto lg:flex lg:justify-between p-4">
        <div><a href="/">Plamo Club</a></div>
        <nav className="header-nav">
          <ul className="lg:flex lg:flex-row space-x-1">
            <li><Link to="/model-kits/">Model Kits</Link></li>
            <li><a href="https://discord.gg/nRVCGNq">Discord</a></li>
          </ul>
        </nav>
      </div>
    </HeaderWrapper>
  );
}
